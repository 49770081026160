import * as React from 'react';
import { Box, Button, Chip, useMediaQuery, useTheme } from '@mui/material';
import {
    List,
    Title,
    useGetResourceLabel,
    Datagrid,
    DateField,
    TextField,
    UrlField,
    TopToolbar,
    SortButton,
    ExportButton,
    FilterButton,
    CreateButton,
    BooleanField,
    useUpdateMany,
    useListContext,
    useUnselectAll,
    useRefresh,
    BulkDeleteButton,
    
    
} from 'react-admin';






const SearchBannersList = () => {
    const theme = useTheme();
    const isXsmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    const getResourceLabel = useGetResourceLabel();

    return (
        <List sort={{ field: 'reference', order: 'ASC' }}
            perPage={25}
            >

                      <Datagrid
                            optimized
                            rowClick="edit"
                            sx={{
                                '& .column-groups': {
                                    md: { display: 'none' },
                                    lg: { display: 'table-cell' },
                                },
                            }}
                        >
                        <TextField source="title"/>
                        <DateField source="created_at" />
                        <UrlField source="url" />
                        <BooleanField source ="is_active"/>    
                        </Datagrid>
       
        </List>
    );
};





export default SearchBannersList;

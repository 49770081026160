import * as React from 'react';
import {
    Datagrid,
    DateField,
    Edit,
    EditButton,
    FormTab,
    ImageField,
    ImageInput,
    Pagination,
    ReferenceManyField,
    required,
    TabbedForm,
    TextField,
    TextInput,
    useRecordContext,
    useGetManyReference,
    useTranslate,
    Link,
    FieldProps,
    BooleanInput,
    SelectInput,
    ReferenceInput,
    AutocompleteArrayInput,
} from 'react-admin';




const SearchBannersEdit = () => (
    <Edit title="Banner">
        <TabbedForm>
            <FormTab
                    label="Banner"
                    sx={{ maxWidth: '40em' }}
                >
                    <ImageField source="image" title="title"/>
                    <TextInput source="title" validate={required()} />
                    <TextInput source="url" validate={required()} />
                    <BooleanInput source ="is_active" validate={required()} />    
                    
                </FormTab>
        </TabbedForm>
    </Edit>
);

const req = [required()];


export default SearchBannersEdit;
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import SearchBannersList from './SearchBannersList'
import SearchBannersCreate from './SearchBannersCreate'
import SearchBannersEdit from './SearchBannersEdit';

export default {
    list: SearchBannersList,
    create: SearchBannersCreate,
    edit: SearchBannersEdit,
    icon: DynamicFeedIcon,
}
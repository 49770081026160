import * as React from 'react';
import { Create, FormTab, TabbedForm, TextInput, required, ImageInput, ImageField, ReferenceInput, AutocompleteInput, useNotify, useRedirect, BooleanInput } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

const SearchBannersCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    
    const onSuccess = (data: any) => {
        notify('ra.notification.created', { messageArgs: { smart_count: 1 } });
        redirect(`/searchbanners`);
    };


    return (
        <Create mutationOptions={{onSuccess}}>
            <TabbedForm defaultValues={{ store: 1 }}>
                <FormTab
                    label="resources.products.tabs.image"
                    sx={{ maxWidth: '40em' }}
                >
                    <TextInput source="title" validate={required()} />
                    <TextInput source="url" validate={required()} />
                    <ImageInput source="image" label="Imagen Banner">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    <BooleanInput source ="is_active" validate={required()} />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default SearchBannersCreate;